// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-animations-js": () => import("./../../../src/pages/animations.js" /* webpackChunkName: "component---src-pages-animations-js" */),
  "component---src-pages-articles-5-reasons-why-companies-should-consider-vr-learning-tool-js": () => import("./../../../src/pages/articles/5-reasons-why-companies-should-consider-vr-learning-tool.js" /* webpackChunkName: "component---src-pages-articles-5-reasons-why-companies-should-consider-vr-learning-tool-js" */),
  "component---src-pages-articles-impress-visitors-sophisticated-safety-induction-life-saving-rules-js": () => import("./../../../src/pages/articles/impress-visitors-sophisticated-safety-induction-life-saving-rules.js" /* webpackChunkName: "component---src-pages-articles-impress-visitors-sophisticated-safety-induction-life-saving-rules-js" */),
  "component---src-pages-articles-innovative-way-train-workers-fatal-hse-risk-js": () => import("./../../../src/pages/articles/innovative-way-train-workers-fatal-hse-risk.js" /* webpackChunkName: "component---src-pages-articles-innovative-way-train-workers-fatal-hse-risk-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-virsat-adipec-2024-js": () => import("./../../../src/pages/articles/virsat-adipec-2024.js" /* webpackChunkName: "component---src-pages-articles-virsat-adipec-2024-js" */),
  "component---src-pages-articles-vr-games-new-way-create-significant-safety-impact-js": () => import("./../../../src/pages/articles/vr-games-new-way-create-significant-safety-impact.js" /* webpackChunkName: "component---src-pages-articles-vr-games-new-way-create-significant-safety-impact-js" */),
  "component---src-pages-articles-vr-games-new-way-make-work-significantly-safer-js": () => import("./../../../src/pages/articles/vr-games-new-way-make-work-significantly-safer.js" /* webpackChunkName: "component---src-pages-articles-vr-games-new-way-make-work-significantly-safer-js" */),
  "component---src-pages-articles-vr-training-reduce-fatal-road-incidents-middle-east-js": () => import("./../../../src/pages/articles/vr-training-reduce-fatal-road-incidents-middle-east.js" /* webpackChunkName: "component---src-pages-articles-vr-training-reduce-fatal-road-incidents-middle-east-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-course-registration-js": () => import("./../../../src/pages/lp/course-registration.js" /* webpackChunkName: "component---src-pages-lp-course-registration-js" */),
  "component---src-pages-lp-download-article-js": () => import("./../../../src/pages/lp/download-article.js" /* webpackChunkName: "component---src-pages-lp-download-article-js" */),
  "component---src-pages-lp-for-testing-js": () => import("./../../../src/pages/lp/for-testing.js" /* webpackChunkName: "component---src-pages-lp-for-testing-js" */),
  "component---src-pages-lp-free-course-registration-js": () => import("./../../../src/pages/lp/free-course-registration.js" /* webpackChunkName: "component---src-pages-lp-free-course-registration-js" */),
  "component---src-pages-lp-newsletter-js": () => import("./../../../src/pages/lp/newsletter.js" /* webpackChunkName: "component---src-pages-lp-newsletter-js" */),
  "component---src-pages-lp-thank-you-form-js": () => import("./../../../src/pages/lp/thank-you-form.js" /* webpackChunkName: "component---src-pages-lp-thank-you-form-js" */),
  "component---src-pages-lp-thank-you-js": () => import("./../../../src/pages/lp/thank-you.js" /* webpackChunkName: "component---src-pages-lp-thank-you-js" */),
  "component---src-pages-lp-vr-games-js": () => import("./../../../src/pages/lp/vr-games.js" /* webpackChunkName: "component---src-pages-lp-vr-games-js" */),
  "component---src-pages-lp-webinar-thank-you-js": () => import("./../../../src/pages/lp/webinar/thank-you.js" /* webpackChunkName: "component---src-pages-lp-webinar-thank-you-js" */),
  "component---src-pages-lp-webinar-thank-you-reg-js": () => import("./../../../src/pages/lp/webinar/thank-you-reg.js" /* webpackChunkName: "component---src-pages-lp-webinar-thank-you-reg-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-virsat-brings-new-virtual-reality-training-adipec-2022-js": () => import("./../../../src/pages/news/virsat-brings-new-virtual-reality-training-adipec-2022.js" /* webpackChunkName: "component---src-pages-news-virsat-brings-new-virtual-reality-training-adipec-2022-js" */),
  "component---src-pages-news-virtual-reality-driving-simulator-js": () => import("./../../../src/pages/news/virtual-reality-driving-simulator.js" /* webpackChunkName: "component---src-pages-news-virtual-reality-driving-simulator-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-flip-cards-js": () => import("./../../../src/pages/products/flip-cards.js" /* webpackChunkName: "component---src-pages-products-flip-cards-js" */),
  "component---src-pages-products-ilfi-js": () => import("./../../../src/pages/products/ilfi.js" /* webpackChunkName: "component---src-pages-products-ilfi-js" */),
  "component---src-pages-products-ilife-saving-rules-js": () => import("./../../../src/pages/products/ilife-saving-rules.js" /* webpackChunkName: "component---src-pages-products-ilife-saving-rules-js" */),
  "component---src-pages-products-interactive-animations-js": () => import("./../../../src/pages/products/interactive-animations.js" /* webpackChunkName: "component---src-pages-products-interactive-animations-js" */),
  "component---src-pages-products-interactive-video-js": () => import("./../../../src/pages/products/interactive-video.js" /* webpackChunkName: "component---src-pages-products-interactive-video-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-organisational-change-projects-js": () => import("./../../../src/pages/products/organisational-change-projects.js" /* webpackChunkName: "component---src-pages-products-organisational-change-projects-js" */),
  "component---src-pages-products-permit-to-work-js": () => import("./../../../src/pages/products/permit-to-work.js" /* webpackChunkName: "component---src-pages-products-permit-to-work-js" */),
  "component---src-pages-products-pre-job-discussions-js": () => import("./../../../src/pages/products/pre-job-discussions.js" /* webpackChunkName: "component---src-pages-products-pre-job-discussions-js" */),
  "component---src-pages-products-safety-meetings-js": () => import("./../../../src/pages/products/safety-meetings.js" /* webpackChunkName: "component---src-pages-products-safety-meetings-js" */),
  "component---src-pages-products-shut-down-training-js": () => import("./../../../src/pages/products/shut-down-training.js" /* webpackChunkName: "component---src-pages-products-shut-down-training-js" */),
  "component---src-pages-products-vr-life-saving-rules-js": () => import("./../../../src/pages/products/vr-life-saving-rules.js" /* webpackChunkName: "component---src-pages-products-vr-life-saving-rules-js" */)
}

